$(document).ready(function(){

    function viewportToPixels(value) {
        var parts = value.match(/([0-9\.]+)(vh|vw)/)
        var q = Number(parts[1])
        var side = window[['innerHeight', 'innerWidth'][['vh', 'vw'].indexOf(parts[2])]]
        return side * (q/100)
    }

    // back-to-top
    $(window).on('scroll', function() {
        if ($(window).scrollTop() > 50) {
            $(".body-homepage").removeClass('top');
        } else {
            $(".body-homepage").addClass('top');
        }
        if ($(window).scrollTop() > viewportToPixels('50vh')) {
            $(".back-to-top").addClass('active');
        } else {
            $(".back-to-top").removeClass('active');
        }
    });

    $('.slick.marquee').slick({
        speed: 5000,
        autoplay: true,
        autoplaySpeed: 0,
        centerMode: true,
        cssEase: 'linear',
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        infinite: true,
        initialSlide: 1,
        arrows: false,
        buttons: false
    });

});